<template>
  <div>
    <el-form ref="form" :model="form" size="small" label-position="top">
      <el-form-item label="用户ID" v-if="!create">
        <span class="help">
          <i class="el-icon-document-copy" v-clipboard:copy="form.uuid"></i>
          {{ form.uuid }}
        </span>
      </el-form-item>

      <el-form-item
        label="名称"
        prop="name"
        :rules="
          create
            ? [
                { required: true, message: $t('rules.require'), trigger: 'blur' },
                {
                  pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
                  message: $t('rules.pattern'),
                  trigger: ['blur', 'change']
                }
              ]
            : [{ required: true, message: $t('rules.require'), trigger: 'blur' }]
        "
      >
        <el-input v-model="form.name" :disabled="!create"></el-input>
        <span class="help">用户名称，必填项，允许小写字母/数字/短横线组成</span>
      </el-form-item>

      <el-form-item
        :label="$t('email')"
        prop="email"
        v-if="general || create"
        :rules="[
          { required: true, message: $t('rules.require'), trigger: 'blur' },
          { type: 'email', message: $t('rules.pattern'), trigger: ['blur', 'change'] }
        ]"
      >
        <el-input v-model="form.email"></el-input>
      </el-form-item>

      <el-form-item
        label="密码"
        prop="password"
        v-if="password || create"
        :rules="[
          { required: true, message: $t('rules.require'), trigger: 'blur' },
          {
            pattern: /^(?=.*[a-z])(?=.*\d)[^]{6,}$/,
            message: $t('rules.login.password'),
            trigger: ['blur', 'change']
          }
        ]"
      >
        <el-input v-model="form.password" type="password" show-password></el-input>
        <span class="help">{{ $t("rules.login.password") }}</span>
      </el-form-item>

      <el-form-item
        label="确认密码"
        prop="verifyPassword"
        v-if="password || create"
        :rules="[{ required: true, validator: validatePassConfirm, trigger: ['blur', 'change'] }]"
      >
        <el-input v-model="form.verifyPassword" type="password" show-password></el-input>
      </el-form-item>

      <el-form-item label="企业管理员" v-if="permission || create">
        <el-radio-group v-model="organizationAdminFlag">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="绑定用户组" v-if="permission || create">
        <el-select v-model="groupUserBindings" filterable multiple style="width: 100%;" v-if="groupList.length > 0">
          <el-option v-for="item in groupList" :key="item.uuid" :label="item.name" :value="item.uuid">
            <div>
              <div class="role-title">{{ item.name }}</div>
              <div class="role-desc">{{ item.description }}</div>
            </div>
          </el-option>
        </el-select>

        <span class="help" v-else>
          企业下暂无用户组，您可以不绑定用户组，或立即
          <a href="javascript:;" @click="$router.push('/admin/group/add')"> [创建用户组]</a>
        </span>

        <span class="help">
          用户首先会继承用户组的权限，若用户未指定所属用户组，才会继承设置的用户权限
        </span>
      </el-form-item>

      <el-form-item label="设置用户权限" v-if="permission || create">
        <el-table :data="providers" v-if="providers.length > 0">
          <el-table-column label="#" width="150">
            <template slot-scope="scope">
              <img :src="aws" v-if="scope.row.kind == 'ProviderAWS'" />
              <img :src="aliyun" v-if="scope.row.kind == 'ProviderALiCloud'" />
              <img :src="gcp" v-if="scope.row.kind == 'ProviderGCP'" />
              <img :src="onpremise" v-if="scope.row.kind == 'ProviderKubeconfig'" />
              <img :src="tencentcloud" v-if="scope.row.kind == 'ProviderTencent'" />
              <img :src="huaweicloud" v-if="scope.row.kind == 'ProviderHuawei'" />
            </template>
          </el-table-column>

          <el-table-column :label="$t('Cluster')" prop="cluster">
            <template slot-scope="scope">
              <div class="title">{{ scope.row.cluster }}</div>
              <div class="desc">{{ scope.row.uuid }}</div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('role')">
            <template slot-scope="scope">
              <el-select size="small" filterable v-model="groupUserRoleBindings[scope.row.uuid]">
                <el-option v-for="item in roleList" :key="item.uuid" :label="item.name" :value="item.uuid">
                  <div>
                    <div class="role-title">角色名：{{ item.name }}</div>
                    <div class="role-desc">{{ item.description ? item.description : "-" }}</div>
                  </div>
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column label="访问状态">
            <template slot-scope="scope">
              <el-radio-group v-model="effectBindings[scope.row.uuid]">
                <el-radio-button label="Allow">允许访问</el-radio-button>
                <el-radio-button label="Deny">禁止访问</el-radio-button>
              </el-radio-group>
            </template>
          </el-table-column>
        </el-table>

        <span class="help" v-else>
          企业下暂无集群，请立即
          <a href="javascript:;" @click="$router.push('/admin/cluster/add')">[创建集群]</a>
        </span>
      </el-form-item>

      <slot name="button"></slot>
    </el-form>
  </div>
</template>

<script>
import provider from "@/mixins/provider";
import { providers } from "api/app";
import { roleList } from "api/role";
import { groupList } from "api/group";

export default {
  props: {
    form: Object,
    create: Boolean,
    general: Boolean,
    password: Boolean,
    permission: Boolean
  },
  mixins: [provider],

  data() {
    return {
      providers: [],
      roleList: [],
      groupList: [],
      groupUserRoleBindings: {},
      effectBindings: {},
      groupUserBindings: [],
      organizationAdminFlag: false
    };
  },

  methods: {
    getProviders() {
      providers(this.organization).then(response => {
        if (response.code === 0) {
          this.providers = response.data.items;

          if (this.create)
            this.providers.forEach(item => {
              this.$set(this.effectBindings, item.uuid, "Allow");
            });
        }
      });
    },

    getRoleList() {
      roleList(this.organization).then(response => {
        if (response.code === 0) {
          this.roleList = response.data.items;
        }
      });
    },

    getGroupList() {
      groupList(this.organization).then(response => {
        if (response.code === 0) {
          this.groupList = response.data.items;
        }
      });
    },

    validatePassConfirm(rule, value, callback) {
      if (value === "") {
        callback(new Error(this.$t("rules.login.passwordConfirm")));
      } else {
        if (value !== this.form.password) {
          callback(new Error(this.$t("rules.login.passwordDiff")));
        }
        callback();
      }
    }
  },

  mounted() {
    if (this.permission || this.create) {
      this.getProviders();
      this.getRoleList();
      this.getGroupList();
    }
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.el-select-dropdown__item {
  min-height: 60px;
}

.role-title {
  font-size: 12px;
  color: $color-main;
  font-weight: 600;
}

.role-desc {
  font-size: 12px;
  color: $color-sub;
  line-height: 1;
}
</style>
